//  Core

.app-sidebar {
  &--header {
    padding: 0 calc($layout-spacer / 2);
    min-height: $header-height;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: calc($layout-spacer / 4);
    background-color: #2B388D !important;

    .expand-sidebar-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      content: '';
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
    }
  }

  .toggle-mobile-sidebar-btn {
    display: none;
    padding: 0;

    .MuiButton-label {
      display: flex;
    }
  }

  // Responsive

  @include media-breakpoint-down(md) {
    .toggle-mobile-sidebar-btn {
      display: block;
    }
  }
}

// Logo wrapper

.app-sidebar-logo {
  display: flex;
  align-items: left;
  transition: all 0.5s $app-sidebar-transition;
  padding: 20px 4px;

  img {
    height: 30px;
    width: 150px;
    margin: 0 auto;
  }

  &:hover {
    color: $black;
    background: rgba(255,255,255,0.08);
  }
}

@-webkit-keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Themes

@import 'themes/dark';
@import 'themes/light';

// Modifiers

@import 'modifiers/collapsed';
