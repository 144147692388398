// Background

// Neutral

@each $color, $value in $theme-colors-neutral {
  @include bg-variant('.bg-neutral-#{$color}', $value);
}

:root {
  @each $color, $value in $theme-colors-neutral {
    --neutral-#{$color}: #{$value};
  }
}

// Brands

@each $color, $value in $brand-colors {
  @include bg-variant('.bg-brand-#{$color}', $value);
}

// Text brands

@each $color, $value in $brand-colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

// Shadows

@each $color, $value in $theme-colors {
  .shadow-#{$color} {
    box-shadow: 0 0.313rem 0.8rem rgba($value, 0.5), 0 0.126rem 0.225rem rgba($value, 0.3) !important;
  }

  .shadow-#{$color}-sm {
    box-shadow: 0 0.46875rem 2.1875rem rgba($value, 0.03), 0 0.9375rem 1.40625rem rgba($value, 0.03),
      0 0.25rem 0.53125rem rgba($value, 0.05), 0 0.125rem 0.1875rem rgba($value, 0.03) !important;
  }
}

// Gradient background

.bg-warm-flame {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%) !important;
}

.bg-night-fade {
  background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%) !important;
}

.bg-sunny-morning {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
}

.bg-tempting-azure {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%) !important;
}

.bg-amy-crisp {
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%) !important;
}

.bg-heavy-rain {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
}

.bg-mean-fruit {
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%) !important;
}

.bg-malibu-beach {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%) !important;
}

.bg-deep-blue {
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) !important;
}

.bg-ripe-malin {
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%) !important;
}

.bg-arielle-smile {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  ) !important;
}

.bg-plum-plate {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
}

.bg-happy-fisher {
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.bg-happy-itmeo {
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
}

.bg-mixed-hopes {
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%) !important;
}

.bg-strong-bliss {
  background-image: linear-gradient(
    to right,
    #f78ca0 0%,
    #f9748f 19%,
    #fd868c 60%,
    #fe9a8b 100%
  ) !important;
}

.bg-grow-early {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%) !important;
}

.bg-love-kiss {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;
}

.bg-premium-dark {
  background-image: linear-gradient(to right, #434343 0%, black 100%) !important;
}

.bg-happy-green {
  background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

.bg-vicious-stance {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
}

.bg-midnight-bloom {
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
}

.bg-night-sky {
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}

.bg-slick-carbon {
  background-image: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%) !important;
  background-blend-mode: multiply;
}

.bg-royal {
  background-image: linear-gradient(to right, #141e30, #243b55) !important;
}

.bg-asteroid {
  background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
}

.bg-skim-blue {
  background-image: linear-gradient(135deg, #abdcff 10%, #0396ff 100%) !important;
}

.bg-light-pure {
  background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%) !important;
}

.bg-nice-redora {
  background-image: linear-gradient(135deg, #f761a1 10%, #8c1bab 100%) !important;
}

.bg-red-lights {
  background-image: linear-gradient(135deg, #f05f57 10%, #360940 100%) !important;
}

.bg-serious-blue {
  background-image: linear-gradient(135deg, #97abff 10%, #123597 100%) !important;
}

.bg-deep-sky {
  background-image: linear-gradient(135deg, #6b73ff 10%, #000dff 100%) !important;
}

.bg-sunrise-purple {
  background-image: linear-gradient(135deg, #3b2667 10%, #bc78ec 100%) !important;
}

// Composed backgrounds

// Gray variations backgrounds

.bg-gray-100 {
  background: $gray-100 !important;
}

.bg-gray-200 {
  background: $gray-200 !important;
}

.bg-gray-300 {
  background: $gray-300 !important;
}

.bg-gray-400 {
  background: $gray-400 !important;
}

.bg-gray-500 {
  background: $gray-500 !important;
}

.bg-white-50 {
  background: rgba($white, 0.45) !important;
}

.bg-white-10 {
  background: rgba($white, 0.1) !important;
}

.bg-white-5 {
  background: rgba($white, 0.06) !important;
}
