// Collapsed Sidebar
.app-sidebar {
  &--header {
    .collapsed-logo{
      display: none;
    }
    .expanded-logo{
      display: inline;
    }
    .collapse-sidebar-btn {
      visibility: visible;
      transition: $transition-base;
      padding: 0;
      width: 36px;
      height: 36px;
      line-height: 34px;
    }

    .expand-sidebar-btn {
      transition: $transition-base;
      position: absolute;
      right: 0;
      width: 36px;
      height: 36px;
      line-height: 34px;
      margin: -18px -18px 0 0;
      top: 50%;
      padding: 0;
      visibility: hidden;
      opacity: 0;
    }

    // Responsive

    @include media-breakpoint-down(md) {
      .expand-sidebar-btn {
        display: none;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .collapsed-logo{
      display: inline;
    }
    .expanded-logo{
      display: none;
    }
    .app-sidebar {
      .collapse-sidebar-btn {
        visibility: hidden;
        opacity: 0;
        display: none;
      }

      &--header {
        .app-sidebar-logo {
          margin: 0 auto;

          &--text {
            opacity: 0;
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            padding: 0;
          }
        }
      }

      &:not(.app-sidebar--mini):hover {
        .app-sidebar--header {
          .app-sidebar-logo {
            margin: 0;

            &--text {
              opacity: 1;
              height: auto;
              width: auto;
              overflow: visible;
              visibility: visible;
              padding: 0 0 0 calc($spacer / 1.5);
            }
          }

          .expand-sidebar-btn {
            visibility: visible;
          }
        }
      }

      &:hover {
        .collapsed-logo{
          display: none;
        }
        .expanded-logo{
          display: inline;
        }
        .app-sidebar--header {
          .expand-sidebar-btn {
            opacity: 1;
          }
        }
      }
    }
  }
}
