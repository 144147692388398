body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.react-pdf__Page {
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
}

// This is conflicting with modal maxWidth="sm".
//.MuiDialog-paperWidthSm {
//  max-width: none !important;
//}
