// Core

@import 'core';

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.text-blue {
  color: #0076ff !important;
}

.text-red {
  color: #ff0000 !important;
}

.statusbar-spacing {
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

// Bill Form
/* TODO If the screen size is 600px wide or less, set the font-size of <div> to 30px */
// @media screen and (max-width: 600px) {
//   .div-client-details{
//     font-size: 16px !important;
//     font-family: 'Gotham', sans-serif !important;
//   }
// }

// @media screen and (min-width: 601px) {
//   .div-client-details {
//     font-size: 24px !important;
//     font-family: 'Gotham', sans-serif !important;
//   }
// }

.user-avatar{
  width: 79px !important;
  height: 79px !important;
}