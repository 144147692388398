.container-user-details{
  height: 150px;
  background-color: white;
}

// Background css
.bg-color-white {
  background-color: white;
}

.container-bill-details{
  width: calc(99%) !important;
}

.grid-new-bill-top{
  height: 80px;
}

.grid-expense-details{
  height: 390px;
}

//Text
.text-total-amount{
  font-size: 34px !important;
  line-height: 22px;
}

.text-balance-amount{
  font-size: 34px !important;
  line-height: 22px;
  /* identical to box height, or 42px */

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;

  /* Light/Secondary/Main */

  color: #D77E28;
}

.text-estimated-amount{
font-size: 34px !important;
line-height: 22px;
/* identical to box height, or 42px */

display: flex;
align-items: center;
letter-spacing: 0.25px;

/* Light/Secondary/Shades/12p*/
background-color: #D77E281F;
border-radius: 4px;
padding-left: 20px;
padding-right: 20px;
}

.text-gray {
  color: #394A64 !important;
}

.text-return-new-bill {
  text-transform: none;
  font-size: 20px !important;
  height: 28px;
  font-style: normal;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Gray / gray-9 */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}